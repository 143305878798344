[data-theme="theme1"] {
  --background-color: #ffffff;
  --primary-color: #f9f7f7;
  --secondary-color: #dbe2ef;
  --accent-color: #3f72af;
  --fourth-color: #112d4e;
  --fiveth-color: #393e46;

  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #da624a;
  --secondary: #6c757d;
  --success: #3ac47d;
  --info: #16aaff;
  --warning: #f7b924;
  --danger: #d92550;
  --light: #eee;
  --dark: #343a40;
  --focus: #444054;
  --alternate: #794c8a;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

[data-theme="theme2"] {
  --primary-color: #f9f7f7;
  --secondary-color: #dbe2ef;
  --accent-color: #3f72af;
  --fourth-color: #112d4e;
  --fourth-color: red;
  --fiveth-color: #393e46;
}

.input-field {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* margin-bottom: 2rem; */
}

.input-field > label {
  margin-bottom: 0.3rem;
  font-size: 1.3rem;
  color: #495057;
}

.input-field > input,
.input-field > input[type="date"],
.input-field > .select-wrapper > select,
.input-field > textarea {
  padding: 3px 12px;
  font-family: "roboto";
  font-size: 1.5rem;
  font-weight: 400;
  outline: none;
  border: 1px solid #b5bbc0;
  border-radius: 0.25rem;
  color: #495057;
  background-color: white;
  width: 100%;
}

textarea {
  resize: none;
}

.input-field > input:focus,
.input-field > input[type="date"]:focus,
.input-field > span > input[type="date"]:focus,
.input-field > .select-wrapper > select:focus,
.input-field > textarea:focus {
  outline: none;
  border: 1px solid #16aaff;
}

button {
  padding: 0rem 1rem;
  min-width: 10rem;
  height: 3rem;
  margin-left: 1rem;
  font-size: 1.4rem;
  border: 1px solid transparent;
  outline: none;
  background-color: #da624a;
  background-color: #16aaff;
  color: #fff;
  box-shadow: 0 6px 12px rgb(134 140 150 / 65%);
  border-radius: 3px;
  cursor: pointer;
}

footer > button:hover {
  border: 1px solid #3f4e61;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
/* input[type="number"] { */
/* -moz-appearance: textfield; */
/* } */

/* Scroll bars */
/* width */
::-webkit-scrollbar {
  width: 1rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

tfoot {
  position: sticky;
  inset-block-end: 0;
}
