.operacija {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  max-width: 90%;
  /* min-height: 45rem; */
}

.MRT-table {
  font-size: 1.3rem;
}
