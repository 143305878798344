.repromaterijal {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: auto;
  min-height: 45rem;
}

.repromaterijal-search-container {
  display: flex;
  align-items: flex-end;
  width: 100%;
  gap: 1rem;
  margin-bottom: 0.5rem;
  position: relative;
}

.repromaterijal-search-container > .input-field:nth-child(1) {
  width: 25%;
  min-width: 15rem;
}

.repromaterijal-search-container > .input-field:nth-child(2) {
  width: 75%;
}

.repromaterijal-search-container > div > select {
  width: 100%;
  padding: 2px 10px;
}

.repromaterijal-search-container > .input-field > input {
  width: 100%;
  height: 3rem;
  padding: 2px 10px;
}

.clear-search {
  font-size: 2.1rem;
  color: #495057;
  position: absolute;
  bottom: 0.4rem;
  right: 0.5rem;
}

.clear-search:hover {
  cursor: pointer;
  color: #16aaff;
}
