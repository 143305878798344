.landing-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: lightyellow;
}

.landing-page > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
