.repromaterial-details {
  margin: 0.5rem 0rem;
  border: 1px solid #bababa;
  min-width: auto;
}

.repromaterial-details > tbody > tr > td {
  padding: 0.5rem 1rem;
  background-color: #fff;
  border-bottom: 1px solid #bababa;
}

.repromaterial-details > tbody > tr:last-child > td {
  border-bottom: none;
  border-bottom: 1px solid #bababa;
}

.repromaterial-details > thead > tr > th {
  background-color: #4e5462;
}
