.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  padding: 50px;
  z-index: 100;
  width: 100%;
  height: 100%;
}

.overlay::before {
  position: fixed;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  background-color: black;
  opacity: 0.5;
  bottom: 0;
}

.message-window {
  display: flex;
  flex-direction: column;
  width: auto;
  min-height: 17.5rem;
  height: auto;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 40px;
  border-radius: 3px;
  z-index: 20;
}
