.vertical-menu-container {
  display: flex;
  width: 100%;
  max-width: 25rem;
  height: 100%;
  margin-right: 0.5rem;
  /* padding: 0.5rem 0.5rem 0.5rem 0.5rem; */
  border-radius: 3px;
  color: #d1d3d4;
  background: linear-gradient(to right, #363b49, #eff0f3);
  /* overflow-y: auto; */
  /* border: 1px solid red; */
  position: relative;
}

.hamburger {
  font-size: 2.5rem;
  margin: 0.5rem;
  cursor: pointer;
}

.vertical-menu-wrapper {
  display: flex;
  flex-direction: column;
  /* flex-wrap: 1; */
  /* margin: 0.5rem; */
  border-radius: 3px;
  height: 100%;
  /* height: 730px; */
  /* min-height: 0rem; */
  /* padding: 0rem 1rem 1rem 1rem; */
  overflow-y: auto;
  overflow-x: hidden;
  /* border: 1px solid blue; */
}

.vertical-menu-wrapper
  > .menu-group:nth-child(1)
  > .menu-group-item:nth-child(1) {
  margin-top: 2.5rem;
}

.vertical-menu-items > a {
  display: flex;
  flex-direction: column;
  margin-top: 1.2rem;
  text-decoration: none;
  font-size: 1.6rem;
  font-weight: 500;
}

.vertical-menu-items > .active {
  color: #393e44;
  color: #2f4075;
  color: rgb(240, 43, 43);
}

.vertical-menu-items > .inactive {
  margin-left: 0rem 1rem;
  color: #fff;
}

.vertical-menu-items > a:hover {
  color: gold;
}
