.main-app-area {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: white;
  border: 1px solid #b9babc;
  /* border: 1px solid blue; */
  border-radius: 3px;
}

/* @media screen and (max-width: 1366px) {
  .main-app-area {
    max-width: 1096px;
    max-height: 569px;
  }
}

@media screen and (max-height: 768px) {
  .main-app-area {
    max-width: 1096px;
    max-height: 569px;
  }
} */
