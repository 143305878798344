.prikljucna-masina-if {
  width: 60rem;
  height: auto;
}

.prikljucna-masina-if > .input-form > .input-field {
  margin-bottom: 1rem;
}

.prikljucna-masina-if > .input-form > .input-field:nth-child(2) > input {
  width: 10rem;
}
