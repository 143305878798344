.masina-prikljucna-masina {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 90%;
  min-height: 40rem;
  /* max-height: 40rem; */
  /* max-height: 90%; */
}

.masina-prikljucna-masina .table-wrapper {
  max-height: 27rem;
}

.masina-prikljucna-masina-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

.masina-prikljucna-masina-left {
  display: flex;
  align-items: flex-start;
  width: auto;
  max-width: 50%;
  height: 100%;
  height: 100%;
}

.masina-prikljucna-masina-right {
  display: flex;
  width: auto;
  max-width: 50%;
  height: 100%;
}

.masina-prikljucna-masina-left > .table-form,
.masina-prikljucna-masina-right > .table-form {
  display: flex;
  align-items: flex-start;
  border: none;
  box-shadow: none;
}

.masina-prikljucna-masina-left
  > .table-form
  > .table-form-main
  > .input-field
  > select {
  padding: 2px 12px;
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
}

.masina-prikljucna-masina-right
  > .table-form
  > .table-form-main
  > .input-field
  > select {
  padding: 2px 12px;
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
}
