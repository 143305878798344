.parcela-if {
  width: 50rem;
  height: auto;
  position: relative;
}

.parcela-if > .input-form > .input-field {
  margin-bottom: 1rem;
}

.parcela-if > .input-form > .input-field:nth-child(2) > input {
  width: 10rem;
}

.parcela-group {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.parcela-if
  > .input-form
  > .parcela-group:nth-child(4)
  > .input-field:nth-child(1) {
  width: 12rem;
}

.parcela-if
  > .input-form
  > .parcela-group
  > .input-field:nth-child(2)
  > select {
  height: 2.6rem;
}

.parcela-if > .input-form > .parcela-group:nth-child(7) > .input-field > input {
  width: 11rem;
}

.majka-cerka-button {
  margin: 0;
  background-color: white;
  box-shadow: none;
  border: 1px solid #b5bbc0;
  color: #495057;
  border-radius: 0;
  height: 2.6rem;
}
