.operacija-if {
  width: 50rem;
  height: auto;
}

.operacija-if > .input-form > .input-field {
  margin-bottom: 1rem;
}

.operacija-if > .input-form > .input-field:nth-child(2) > input {
  width: 10rem;
}

.koristi-repromaterijal-wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
