.utrosak-goriva-i-repromaterijala-container {
  display: flex;
  flex-direction: column;
  width: 60rem;
  height: 42rem;
  padding: 2rem;
  background-color: white;
  border: 1px solid #b5bbc0;
  border-radius: 0.25rem;
  z-index: 600;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.utrosak-goriva-i-repromaterijala-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: auto;
  font-size: 2rem;
  color: #495057;
  margin-bottom: 2rem;
}

.utrosak-goriva-i-repromaterijala-filter {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.utrosak-goriva-i-repromaterijala-filter-row {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.utrosak-goriva-i-repromaterijala-filter-row > .input-field > input,
.utrosak-goriva-i-repromaterijala-filter-row > .input-field > select {
  height: 2.6rem;
  font-size: 1.4rem;
}

.utrosak-goriva-i-repromaterijala-filter-row:nth-child(1)
  > .input-field
  > input {
  width: 14rem;
  font-size: 1.4rem;
}
.utrosak-goriva-i-repromaterijala-filter-row:nth-child(1)
  > .input-field
  > select {
  width: 26rem;
  font-size: 1.4rem;
}

.utrosak-goriva-i-repromaterijala-container > button {
  width: 100%;
  margin: 0.5rem 0rem;
  height: 2.6rem;
}

.utrosak-goriva-i-repromaterijala-container
  > .table-form-main
  > .table-wrapper {
  width: 100%;
  min-height: 16rem;
  max-height: 16rem;
}
