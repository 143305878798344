.radni-nalog-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  max-height: 90%;
  /* border: 2px solid #333; */
}

.radni-nalog-table-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 100%;
  background-color: #fff;
  border: 1px solid #bababa;
  /* border: 2px solid greenyellow; */
  overflow-y: auto;
}

/* .radni-nalog-table-form-main-table-wrapper {
  width: 80%;
  height: 100%;
  background-color: aqua;
  border: 2px solid red;
} */

.radni-nalog-table-form > .radni-nalog-table-form-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 3.5rem;
  padding-left: 1rem;
  position: relative;
  background-color: #16aaff;
  background-color: #4e5462;
  color: #fff;
}

.select {
  max-height: 2.5rem;
  font-size: 1.4rem;
}

.preview {
  width: 1.5rem;
  cursor: pointer;
}

.radni-nalog-table-form-main {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  /* border: 2px solid green; */
  padding: 1rem;
}

.radni-nalog-table-form-main > .filter-i-utrosci {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0.2rem 0;
  /* border: 1px solid red; */
}

.radni-nalog-table-form-main-table-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: auto;
  max-height: 250px;
  border: 1px solid #b5bbc0;
  /* border: 5px solid blue; */
}

.tabela > thead {
  position: sticky;
  top: 0;
}

.filter {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 80%;
  padding: 1rem;
  gap: 1rem;
  font-size: 1.4em;
}

.box-1,
.box-2,
.box-3,
.box-4,
.box-5 {
  display: flex;
  flex-direction: column;
  width: 30rem;
  height: 100%;
  gap: 0.5rem;
}

.box-1 > .input-field > input,
.box-2 > .input-field > select,
.box-3 > .input-field > select,
.box-4 > .input-field > select,
.box-5 > .input-field > select {
  padding: 0 10px;
  height: 2.7rem;
}

.box-1 {
  width: 15rem;
}

.box-5 {
  align-items: center;
  justify-content: space-between;
  width: 20rem;
  height: 100%;
}

.radni-nalog-table-form-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  min-height: 6rem;
  padding: 0rem 2rem;
  border-top: 1px solid #bababa;
  /* border: 2px solid goldenrod; */
}

.radni-nalog-table-form-footer-left {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
}

.radni-nalog-table-form-footer-left > div > input[type="date"] {
  padding: 3px 12px;
  font-family: "roboto";
  font-size: 1.2rem;
  font-weight: 400;
  outline: none;
  border: 1px solid #b5bbc0;
  border-radius: 0.25rem;
  color: #495057;
  background-color: white;
  width: 12rem;
  margin-left: 1rem;
}

.radni-nalog-table-form-footer-right {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
}

.radni-nalog-table-form-footer-left > button,
.radni-nalog-table-form-footer-right > button {
  min-width: 11rem;
}

.radni-nalog-table-form-footer-left > button:hover,
.radni-nalog-table-form-footer-right > button:hover {
  border: 1px solid #3f4e61;
}

.filter-icon {
  width: 2.2rem;
  height: 2.2rem;
  object-fit: cover;
}

.close-button {
  position: absolute;
  right: 1rem;
  color: white;
  font-size: 2rem;
  font-weight: 900;
}

.close-button:hover {
  color: #16aaff;
}

.radni-nalog-export-to-excel {
  background-color: #19ac65;
}
