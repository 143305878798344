.operacija-repromaterijal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: auto;
  min-height: 50rem;
}

.operacija-repromaterijal-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

.operacija-repromaterijal-left {
  display: flex;
  align-items: baseline;
  width: 100%;
  height: 100%;
}

.operacija-repromaterijal-right {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.operacija-repromaterijal-left > .table-form,
.operacija-repromaterijal-right > .table-form {
  display: flex;
  align-items: flex-start;
  border: none;
  box-shadow: none;
}

.operacija-repromaterijal-right
  > .table-form
  > .table-form-main
  > .input-field
  > select {
  padding: 2px 12px;
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
}
