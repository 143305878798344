.login-page {
  display: grid;
  grid-template-columns: 1fr 2fr;
  width: 100vw;
  min-height: 100vh;
}

.login-page-left {
  grid-column: 1/2;
  background-image: url("../../images/backgrounds/splash16.jpeg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.9;
}

.login-page-right {
  display: grid;
  grid-column: 2/3;
  align-content: center;
  padding: 0 18rem;
  background-color: var(--background-color);
  color: #495057;
}

.login-page-right-top > .logo {
  width: 5rem;
  margin-bottom: 5rem;
}

.login-page-right-top > p > span {
  font-size: 1.8rem;
  font-weight: 400;
}

.login-page-right-top > p:nth-child(2) > span {
  font-size: 2.5rem;
  font-weight: 400;
}

.login-page-right-top > p:nth-child(4) {
  margin-top: 3rem;
}

.login-page-right-bottom {
  display: flex;
  gap: 20px;
  margin-top: 4rem;
}

.login-page-right > .login-page-right-bottom > .input-field {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.login-page-right > .login-page-right-bottom .input-field > label {
  margin-bottom: 0.8rem;
  font-size: 1.4rem;
}

.login-page-right > .login-page-right-bottom .input-field > input {
  padding: 6px 12px;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  outline: none;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #495057;
  background-color: white;
}

.login-page-right > .login-page-right-bottom .input-field > input:focus {
  border: 1px solid #16aaff;
}

.login-page-right > .login-group {
  display: flex;
  width: 100%;
  height: 4rem;
  justify-content: space-between;
  align-items: center;
  margin-top: 4rem;
}

.navlink {
  color: #da624a;
  text-decoration: none;
}

.login-page-right > .login-group > .login-button {
  width: 15rem;
  height: 3rem;
  margin-left: auto;
  outline: none;
  border: 1px solid #16aaff;
  border-radius: 0.25rem;
  background-color: #16aaff;
  color: white;
  font-size: 1.6rem;
  /* line-height: 1.5; */
  /* padding: 1rem 1.6rem; */
  /* padding-bottom: 2rem !important; */
  cursor: pointer;
}
