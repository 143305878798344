.ok-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  background-color: white;
}

.ok-message-tekst {
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
}

.ok-message-tekst > img {
  width: 3.5rem;
  height: 3.5rem;
  object-fit: cover;
  margin-right: 2rem;
}

.ok-message-buttons {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.ok-message-buttons > button {
  min-width: 10rem;
}
