@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;800&family=Roboto:wght@100;300;400&display=swap");

html {
  width: 100%;
  height: 100%;
  /* font-family: "Montserrat", sans-serif; */
  font-size: 62.5%;
}

body {
  width: 100%;
  height: 100%;
  font-size: 1.6em;
  font-family: "Roboto", sans-serif;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.app {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid yellow; */
}
