.majka-cerka-container {
  display: flex;
  flex-direction: column;
  top: 13rem;
  right: 2.5rem;
  position: absolute;
  z-index: 200;
  padding: 2.5rem;
  background-color: white;
  border: 1px solid #b5bbc0;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.majka-cerka-container > .parcela-group > .input-field > .parcela-majka {
  width: 12rem;
  height: 2.6rem;
  font-size: 1.4rem;
}

.majka-cerka-container > .parcela-group > .input-field > .procenat-troskova {
  width: 6rem;
  font-size: 1.4rem;
}

.majka-cerka-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 3rem;
  margin-bottom: 3.5rem;
  position: relative;
}

.majka-cerka-close-button {
  font-size: 2rem;
  color: #495057;
}

.majka-cerka-close-button:hover {
  color: #16aaff;
}
