.register-page {
  display: grid;
  grid-template-columns: 1fr 2fr;
  width: 100vw;
  min-height: 100vh;
}

.register-page-left {
  grid-column: 1/2;
  background-image: url("../../images/backgrounds/splash16.jpeg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  /* opacity: 0.9; */
}

.register-page-right {
  display: grid;
  grid-column: 2/3;
  align-content: center;
  padding: 0 18rem;
  background-color: var(--background-color);
  color: #495057;
}

.register-page-right-top > .logo {
  width: 5rem;
  margin-bottom: 5rem;
}

.register-page-right-top > p > span {
  font-size: 1.8rem;
  font-weight: 400;
}

.register-page-right-top > p:nth-child(2) > span {
  font-size: 2.5rem;
  font-weight: 400;
}

.register-page-right-bottom > .input-group {
  display: flex;
  gap: 20px;
  margin-top: 4rem;
}

.register-page-right
  > .register-page-right-bottom
  > .input-group
  > .input-field {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.register-page-right
  > .register-page-right-bottom
  > .input-group
  .input-field
  > label {
  margin-bottom: 0.8rem;
  font-size: 1.4rem;
}

.register-page-right
  > .register-page-right-bottom
  > .input-group
  .input-field
  > input {
  padding: 6px 12px;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  outline: none;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #495057;
  background-color: white;
}

.register-page-right
  > .register-page-right-bottom
  > .input-group
  .input-field
  > input:focus {
  border: 1px solid #16aaff;
}

.register-page-right > .register-group {
  display: flex;
  width: 100%;
  margin-top: 5rem;
  justify-content: space-evenly;
  align-items: center;
}

.register-page-right > .register-group > p {
  font-size: 1.6rem;
  font-weight: 400;
}
.register-page-right > .register-group > p > a {
  color: #da624a;
  text-decoration: none;
}

.register-page-right > .register-group > button {
  width: 15rem;
  /* height: 100%; */
  margin-left: auto;
  outline: none;
  border: 1px solid #da624a;
  border-radius: 0.25rem;
  background-color: #da624a;
  color: white;
  font-size: 1.6rem;
  /* line-height: 1.5;
  padding: 0.8rem 1.6rem; */
  cursor: pointer;
}

.asterisk {
  color: red;
}
