.table-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  height: auto;
  background-color: #fff;
  border: 1px solid #4e5462;
  border: 1px solid #bababa;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.table-form > .table-form-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 3.5rem;
  padding-left: 1rem;
  position: relative;
  background-color: #16aaff;
  background-color: #4e5462;
  color: #fff;
}

.table-form > .table-form-main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 2.5rem;
}

.table-wrapper {
  display: flex;
  width: 100%;
  max-width: 100%;
  max-height: 30rem;
  overflow-x: auto;
  overflow-y: auto;
  border: 1px solid #b5bbc0;
}

table {
  overflow-y: auto;
  table-layout: fixed;
  border-collapse: collapse;
  min-width: 35rem;
  min-width: 100%;
  height: 100%;
}

thead {
  height: 3rem;
  position: sticky;
  top: 0;
  background-color: #362f4b;
  background-color: #16aaff;
  background-color: #575f6c;
  background-color: #4e5462;
}

th {
  color: #fff;
  font-size: 1.5rem;
  font-weight: normal;
  white-space: nowrap;
  text-align: left;
}

tr {
  /* padding-left: 1rem;
  padding-right: 1rem; */
  border-bottom: 1px solid #b5bbc0;
}

thead > tr > * {
  color: #fff;
  vertical-align: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

tr:nth-child(even) {
  background-color: #e8ecef;
}

td {
  padding: 0.5rem 1rem;
  font-size: 1.3rem;
  white-space: nowrap;
}

tbody {
  overflow-x: auto;
  overflow-y: auto;
}

.number-of-rows {
  margin-top: 1rem;
  font-size: 1.4rem;
  color: #495057;
}

.table-form > .table-form-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  min-height: 6rem;
  padding: 0rem 2rem;
  border-top: 1px solid #bababa;
}

.table-form > .table-form-footer > button {
  min-width: 10rem;
}

.table-form > .table-form-footer > button:hover {
  border: 1px solid #3f4e61;
}

.close-button {
  position: absolute;
  top: 0.7rem;
  right: 1rem;
  color: white;
  font-size: 2rem;
  font-weight: 900;
  cursor: pointer;
}

.close-button:hover {
  color: #16aaff;
}
