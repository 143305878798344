.main-app-page {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 5.5rem 1fr;
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  /* border: 1px solid red; */
}

.main-app-page-container {
  display: flex;
  width: 100%;
  height: 100%;
  /* border: 1px solid green; */
}

/* @media screen and (max-height: 768px) {
  .main-app-page {
    max-width: 1366px;
    max-height: 625px;
  }
}

@media screen and (max-height: 768px) {
  .main-app-page-container {
    max-width: 1096px;
    max-height: 548px;
  }
} */
