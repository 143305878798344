.promeni-oj {
  display: flex;
  flex-direction: column;
  width: 50rem;
  padding: 4rem;
  background-color: white;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  position: relative;
}

.promeni-oj-top > p > span {
  font-size: 2.5rem;
  font-weight: 400;
}

.promeni-oj-bottom > .input-group {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 2.5rem;
}

.promeni-oj > .promeni-oj-bottom > .input-group > .input-field {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.promeni-oj > .promeni-oj-bottom > .input-group > .input-field > label {
  margin-bottom: 0.8rem;
  font-size: 1.4rem;
}

.promeni-oj > .register-group {
  display: flex;
  width: 100%;
  margin-top: 5rem;
  justify-content: space-evenly;
  align-items: center;
}

.promeni-oj > .register-group > button {
  width: 13rem;
  margin-left: auto;
  outline: none;
  border: 1px solid #16aaff;
  border-radius: 0.25rem;
  background-color: #16aaff;
  color: white;
  font-size: 1.6rem;
  cursor: pointer;
}

.input-field > select {
  height: 3rem;
  padding: 3px 12px;
  font-family: "roboto";
  font-size: 1.5rem;
  font-weight: 400;
  outline: none;
  border: 1px solid #b5bbc0;
  border-radius: 0.25rem;
  color: #495057;
  background-color: white;
  width: 100%;
}

.input-field > select:focus {
  outline: none;
  border: 1px solid #16aaff;
}

.promeni-oj-close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: #454a57;
  font-size: 2rem;
  font-weight: 900;
}

.promeni-oj-close-button:hover {
  color: #16aaff;
}
