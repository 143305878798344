.no_internet_connection {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 200;
  width: 100%;
  height: 100%;
}

.no_internet_connection > image {
  width: 100%;
  height: 100%;
  z-index: 201;
}

.no_internet_connection::before {
  position: fixed;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  background-color: black;
  opacity: 0.75;
  bottom: 0;
}
