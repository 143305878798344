/* .datum {
  width: 150px;
  border: 1px solid red;
} */

.radni-nalog-input-form {
  display: flex;
  flex-direction: column;
  height: auto;
  position: relative;
  background-color: white;
  padding: 3rem;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.radni-nalog-input-form > .radni-nalog-title-bar {
  width: 100%;
  margin-bottom: 3rem;
  font-size: 3rem;
  font-weight: 500;
  position: relative;
}

.radni-nalog-input-form > .radni-nalog-title-bar > .close-button {
  color: #454a57;
}

.radni-nalog-input-form > .radni-nalog-title-bar > .close-button:hover {
  color: #16aaff;
}

.radni-nalog-input-form > .radni-nalog-title-bar > span {
  font-size: 2rem;
  font-weight: 500;
}

.radni-nalog-input-form > .radni-nalog-broj-i-datum {
  display: flex;
  width: 100%;
  height: auto;
  padding: 0rem 0rem 2rem 0rem;
}

.radni-nalog-input-form
  > .radni-nalog-broj-i-datum
  > .input-field:nth-child(2) {
  width: auto;
  right: 0;
}

.radni-nalog-input-form
  > .radni-nalog-broj-i-datum
  > .input-field:nth-child(2)
  > span
  input[type="date"] {
  padding: 3px 12px;
  font-family: "roboto";
  font-size: 1.2rem;
  font-weight: 400;
  outline: none;
  border: 1px solid #b5bbc0;
  border-radius: 0.25rem;
  color: #495057;
  background-color: white;
  width: 12rem;
}

.radni-nalog {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 3rem;
}

.radni-nalog > .radni-nalog-left {
  width: 50%;
  height: 100%;
}

.radni-nalog
  > .radni-nalog-left
  > .radni-nalog-wizard:nth-child(1)
  > .radni-nalog-wizard-right:nth-child(2) {
  display: flex;
  align-items: flex-end;
  font-size: 1.3rem;
  color: #495057;
  /* width: 100px; */
}

.usluga-ub {
  display: flex;
  width: 10rem;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  padding: 0 0 0 0;
  margin: 0 0 0 1rem;
}

.razdelna-linija {
  width: 1px;
  min-height: 29.5rem;
  background-color: #3f4e61;
}

.radni-nalog > .radni-nalog-right {
  width: 50%;
  height: 100%;
  position: relative;
}

.radni-nalog > .radni-nalog-right > div:nth-child(3) {
  margin-top: 4rem;
}

.radni-nalog-wizard {
  position: relative;
  display: flex;
  width: 100%;
  height: auto;
  align-items: flex-start;
  margin-bottom: 1.5rem;
}

.radni-nalog-wizard-left {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 4.6rem;
  margin-right: 1.5rem;
  border: 2px solid #3f4e61;
  border-radius: 3px;
}

.radni-nalog-wizard-right {
  display: flex;
  width: 40.5rem;
  height: auto;
}

.radni-nalog-input-form > footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 4rem;
}

.radni-nalog-input-form > footer > button {
  min-width: 10rem;
}

.radni-nalog-if-preview {
  display: none;
}

.radni-nalog-input-form > .input-field > span {
  font-weight: 600;
  color: #3f4e61;
}

.input-field-content {
  display: flex;
  /* justify-content: space-evenly; */
  align-items: center;
  width: 100%;
  height: auto;
  gap: 0.5rem;
  /* border: 1px solid red; */
}

.radni-nalog-wizard-right
  > .input-field-content
  > .input-field:nth-child(1)
  > select {
  width: 100%;
}
.radni-nalog-wizard-right > .input-field-content > .rn-kultura {
  width: max-content;
}

.radni-nalog-wizard-right > .input-field-content > .rn-kultura > div {
  min-width: 12rem;
  width: max-content;
  height: 2.8rem;
  padding: 0.3rem 0.5rem;
  font-size: 1.5rem;
  border-radius: 0.25rem;
  border: 1px solid #b5bbc0;
  color: #495057;
}

#root
  > div
  > div
  > div
  > div.main-app-area
  > div
  > div.radni-nalog
  > div.radni-nalog-left
  > div:nth-child(3)
  > div.radni-nalog-wizard-right
  > div
  > div:nth-child(1) {
  width: 20rem;
}

#root
  > div
  > div
  > div
  > div.main-app-area
  > div
  > div.radni-nalog
  > div.radni-nalog-left
  > div:nth-child(3)
  > div.radni-nalog-wizard-right
  > div
  > div.input-field.rn-kultura.rn-masina {
  width: 100%;
}

#root
  > div
  > div
  > div
  > div.main-app-area
  > div
  > div.radni-nalog
  > div.radni-nalog-left
  > div:nth-child(3)
  > div.radni-nalog-wizard-right
  > div
  > div.input-field.rn-kultura.rn-masina
  > div {
  width: auto;
}

.kultura-select {
  background-color: #16aaff;
}

.input-field-content > .input-field > div,
.input-field-content > .input-field > input {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 5rem; */
  /* height: 2.5rem; */
  font-size: 1.3rem;
  /* border: 1px solid #b5bbc0; */
  /* border: 1px solid blue; */
}

.input-field-content > .input-field:nth-child(3) > input[type="number"]:invalid,
.input-field-content
  > .input-field:nth-child(4)
  > input[type="number"]:invalid {
  border: 1px solid red;
}

.radni-nalog > .radni-nalog-right > .radni-nalog-wizard:nth-child(3) {
  margin-top: 9rem;
}

.zatvori-nalog {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
  font-size: 1.4rem;
  color: #3f4e61;
}

.outer-container {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 400px;
  height: auto;
  /* padding: 3px; */
  background-color: white;
  /* border: 1px solid royalblue; */
  z-index: 2;
}

.inner-container {
  /* left: 0; */
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 2px;
  background-color: white;
  /* border: 1px solid red; */
  max-height: 9rem;
  overflow-y: auto;
  z-index: 2;
  box-shadow: 3px 6px 12px rgb(134 140 150 / 65%);
}

.show-hide-repromaterijal {
  margin-top: 5px;
  color: #333;
  color: #da624a;
  color: #16aaff;
}

.repromaterijal-wrapper {
  margin-top: 2rem;
  color: red;
}
.repromaterijal-wrapper > .wrapper select {
  min-width: 25rem;
  font-size: 1.3rem;
}

.repromaterijal-wrapper > .wrapper input {
  padding: 3px 12px;
  font-size: 1.3rem;
  height: 2.8rem;
}

.open-repromaterijal-btn {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 8rem;
  height: 2.8rem;
  outline: none;
  border: 1px solid #b5bbc0;
  border-radius: 0.25rem;
  background-color: white;
  color: #495057;
  box-shadow: none;
}

.gotova-roba {
  display: flex;
  justify-content: space-between;
  /* border: 1px solid red; */
  gap: 0.5rem;
}

.gotova-roba-left {
  /* border: 1px solid green; */
  width: 100%;
}

.gotova-roba-right {
  display: flex;
  /* border: 1px solid blue; */
  /* width: 10rem; */
  width: 100%;
  gap: 0.5rem;
}

.gotova-roba-left > .input-field > select {
  /* background-color: red; */
  height: 2.5rem;
  margin-bottom: 3px;
  font-size: 1.4rem;
}

.gotova-roba-right > .input-field > input[type="number"] {
  /* background-color: red; */
  height: 2.5rem;
  margin-bottom: 3px;
  font-size: 1.4rem;
}
