.radni-nalog-preview-input-form {
  display: flex;
  flex-direction: column;
  height: auto;
  position: relative;
  background-color: white;
  padding: 3rem;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.radni-nalog-preview-input-form > .radni-nalog-preview-title-bar {
  width: 100%;
  margin-bottom: 3rem;
  font-size: 3rem;
  font-weight: 500;
  position: relative;
}

.radni-nalog-preview-input-form
  > .radni-nalog-preview-title-bar
  > .close-button {
  display: flex;
  color: #454a57;
}

.radni-nalog-preview-input-form
  > .radni-nalog-preview-title-bar
  > .close-button:hover {
  color: #16aaff;
}

.radni-nalog-preview-input-form > .radni-nalog-preview-title-bar > span {
  font-size: 2rem;
  font-weight: 500;
}

.radni-nalog-preview-input-form > .radni-nalog-preview-broj-i-datum {
  display: flex;
  width: 100%;
  height: auto;
  padding: 0rem 0rem 2rem 0rem;
}

.usluga-ub {
  display: flex;
  width: 10rem;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  padding: 0 0 0 0;
  margin: 0 0 0 1rem;
}

.radni-nalog-preview {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 3rem;
}

.radni-nalog-preview > .radni-nalog-preview-left {
  width: 50%;
  height: 100%;
}

.radni-nalog-preview > .radni-nalog-preview-right {
  width: 50%;
  height: 100%;
  position: relative;
}

.radni-nalog-preview > .radni-nalog-preview-right > div:nth-child(3) {
  margin-top: 4rem;
}

.radni-nalog-preview-wizard {
  position: relative;
  display: flex;
  width: 100%;
  height: auto;
  align-items: flex-start;
  margin-bottom: 1.5rem;
}

.radni-nalog-preview-wizard-left {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 4.6rem;
  margin-right: 1.5rem;
  border: 2px solid #3f4e61;
  border-radius: 3px;
}

.radni-nalog-preview-wizard-right {
  display: flex;
  width: 40.5rem;
  height: auto;
}

.radni-nalog-preview-wizard-right
  > .input-field-content
  > .input-field
  > .item-border {
  display: flex;
  justify-content: left;
  font-size: 1.5rem;
}

.item-border {
  display: flex;
  justify-content: left;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 3px 12px;
  font-size: 1.5rem;
  color: #495057;
  border: 1px solid #b5bbc0;
  height: 2.8rem;
  border-radius: 0.25rem;
}

.radni-nalog-preview-wizard-right > .input-field-content > .rn-kultura,
.radni-nalog-preview-wizard-right > .input-field-content > .rn-registracija {
  display: flex;
  max-width: max-content;
}

.zatvori-nalog {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 5.5rem;
  font-size: 1.5rem;
  color: #3f4e61;
}

/* .outer-container {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 400px;
  height: auto;
  background-color: white;
  border: 1px solid royalblue;
  z-index: 2;
} */

.show-hide-repromaterijal {
  margin-top: 5px;
  color: #333;
  color: #da624a;
  color: #16aaff;
}

.repromaterijal-wrapper {
  margin-top: 2rem;
  color: red;
}

.open-repromaterijal-btn {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 8rem;
  height: 2.8rem;
  outline: none;
  border: 1px solid #b5bbc0;
  border-radius: 0.25rem;
  background-color: white;
  color: #495057;
  box-shadow: none;
}
