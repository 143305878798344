/* .box-1,
.box-5 {
  display: flex;
  flex-direction: row;
} */

.export-to-sap-filter {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  margin: 1.5rem 0rem;
  gap: 2rem;
}

.export-to-sap-filter > .input-field > input[type="date"] {
  width: 14rem;
  height: 2.8rem;
  font-size: 1.4rem;
}

.export-to-sap-filter > .input-field > select {
  height: 2.8rem;
  font-size: 1.4rem;
}
