.input-form {
  width: 100%;
  height: auto;
  background-color: #fff;
  padding: 4rem 4rem 4rem 4rem;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  z-index: 20;
}

.input-form > .title-bar {
  width: 100%;
  margin-bottom: 4rem;
  font-size: 3rem;
  font-weight: 500;
}

.input-form > footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 4rem;
}
