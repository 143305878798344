.parcela {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  min-height: 45rem;
}

.parcela > .table-form > .table-form-main > .input-field {
  margin-bottom: 1rem;
}

.parcela > .table-form > .table-form-main > .input-field > select {
  min-width: 25rem;
  width: 50%;
}
