.menu-group {
  height: auto;
  /* border: 1px solid crimson; */
}

.menu-group-item {
  width: 100%;
  /* height: 100%; */
  margin-top: 3rem;
  padding: 0.5rem 1rem;
  font-size: 1.4rem;
  background-color: #a0a4a8;
  color: white;
  color: #393e44;
  cursor: pointer;
  border-radius: 3px;
}

.menu-group:last-child {
  margin-bottom: 3rem;
}
