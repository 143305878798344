.repromaterijal-if {
  width: 50rem;
  height: auto;
}

.repromaterijal-if > .input-form > .input-field {
  margin-bottom: 1rem;
}

.repromaterijal-if > .input-form > .input-field:nth-child(2) > input,
.repromaterijal-if > .input-form > .input-field:nth-child(6) > input {
  width: 10rem;
}
