.popupWindow {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 6rem;
  /* right: -4rem; */
  min-width: max-content;
  height: auto;
  background-color: #f3f3f3;
  border-radius: 3px;
  box-shadow: 0 0 4px 0.5px #a7acb2;
  border: 1px solid #16aaff;
  z-index: 10;
}

.popupWindow::before {
  content: "";
  position: absolute;
  rotate: 45deg;
  width: 1rem;
  height: 1rem;
  top: -0.2rem;
  right: 45%;
  transform: translateX(-50%);
  background-color: #f3f3f3;
  border-top: 1px solid #16aaff;
  border-left: 1px solid #16aaff;
}

.popup-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem 2rem;
  font-size: 1.4rem;
  color: #333;
  transition: 0.5s ease;
  transform: translateX(0%);
}

.popup-menu > p {
  width: 100%;
  margin-bottom: 0.5rem;
}

.popup-menu > p:nth-child(5) {
  margin-top: 1rem;
}

.popup-menu > p > a {
  text-decoration: none;
  color: #333;
}

.popup-menu > p > a:hover {
  color: #38b8ff;
  font-weight: 500;
}
