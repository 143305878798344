.unos-repromaterijala-container {
  display: flex;
  flex-direction: column;
  width: 47.5rem;
  height: 40rem;
  padding: 1rem;
  background-color: white;
  border: 1px solid #b5bbc0;
  border-radius: 0.25rem;
  z-index: 600;
  position: absolute;
  top: 3rem;
  right: 3rem;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.unos-repromaterijala-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 3rem;
  margin-bottom: 1.5rem;
  /* border: 1px solid red; */
  position: relative;
}

.repromaterijal-close-button {
  font-size: 2rem;
  color: #495057;
}

.repromaterijal-close-button:hover {
  color: #16aaff;
}

.unos-repromaterijala-input {
  width: 100%;
  height: 100%;
  padding: 1rem 0rem;
  /* border: 1px solid blue; */
}

.unos-repromaterijala-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 2.7rem;
  /* border: 1px solid green; */
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.unos-repromaterijala-wrapper > span {
  font-size: 1.3;
}

.unos-repromaterijala-wrapper > input {
  width: 8rem;
  height: 100%;
  padding: 3px 12px;
  font-size: 1.3rem;
  outline: none;
  border: 1px solid #b5bbc0;
  border-radius: 0.25rem;
}

.unos-repromaterijala-wrapper > input:focus {
  border-color: #16aaff;
}

.select {
  width: 100%;
}

.uradjeno {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7rem;
  height: 2.5rem;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  border: 1px solid #b5bbc0;
  border-radius: 0.25rem;
}
