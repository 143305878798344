.rasknjizavanje-goriva-filter {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  padding: 1rem 1rem 0.5rem 1rem;
  /* border: 1px solid black; */
}

.rasknjizavanje-goriva-filter > .rasknjizavanje-goriva-filter-left {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 0.5rem;
  /* border: 1px solid green; */
}

.rasknjizavanje-goriva-filter > .rasknjizavanje-goriva-filter-right {
  display: flex;
  flex-direction: column;
  /* border: 1px solid blue; */
}

.rasknjizavanje-goriva-filter
  > .rasknjizavanje-goriva-filter-left
  > .godina-i-mesec {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  /* border: 1px solid pink; */
}

.rasknjizavanje-goriva-filter
  > .rasknjizavanje-goriva-filter-left
  > .godina-i-mesec
  > .input-field
  > select {
  height: 2.6rem;
  font-size: 1.4rem;
}

.rasknjizavanje-goriva-filter
  > .rasknjizavanje-goriva-filter-left
  > .godina-i-mesec
  > .input-field:nth-child(1)
  > select {
  width: 8rem;
}

.rasknjizavanje-goriva-filter
  > .rasknjizavanje-goriva-filter-left
  > .godina-i-mesec
  > .input-field:nth-child(2)
  > select {
  width: 11.5rem;
}

.rasknjizavanje-goriva-filter
  > .rasknjizavanje-goriva-filter-left
  > .masina-i-operacija {
  display: flex;
  flex-direction: column;
  width: 110%;
  gap: 0.5rem;
  /* border: 1px solid crimson; */
}

.rasknjizavanje-goriva-filter
  > .rasknjizavanje-goriva-filter-left
  > .godina-i-mesec
  > .input-field:nth-child(3)
  > .select-repromaterijal {
  min-width: 100%;
}
