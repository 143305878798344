header {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 1rem 1rem;
  background-color: #fff;
  color: #333;
}

.header__left {
  display: flex;
  align-items: center;
}

.header__left > a > img {
  width: 4rem;
  height: 4rem;
  border: 1px solid transparent;
  object-fit: contain;
  -webkit-animation: rotateY 5s infinite linear;
  animation: rotateY 5s infinite linear;
}

@-webkit-keyframes rotateY {
  to {
    -webkit-transform: rotateY(360deg);
  }
}

@keyframes rotateY {
  to {
    transform: rotateY(360deg);
  }
}

.header__left > *:hover {
  cursor: pointer;
  border: 1px solid whitesmoke;
  border-radius: 2px;
  object-fit: contain;
}

.header__middle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 12;
}

.header__middle > h2 {
  margin-left: 1.5rem;
  font-weight: 600;
  color: #da624a;
}

.header__right {
  display: flex;
  align-items: center;
}

.userLogin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: 5rem;
}

.userLogin > p {
  font-size: 1.4rem;
}

.userLogin > img {
  display: flex;
  width: 3rem;
  height: 3rem;
  margin-bottom: 0.5rem;
  object-fit: cover;
}

.userLogin > img:hover {
  cursor: pointer;
}
